import type { ApolloClient, DocumentNode } from '@apollo/client'
import type { CommentElementResponses, QueryCommentElementResponsesArgs } from '@eo/graphql-types'

import { gql } from '@apollo/client'

const GET_OPEN_RESPONSES = gql(`
  query GetOpenResponsesRoot($where: CommentElementResponseWhereInput, $sort: CommentElementResponseSortInput, $limit: Int, $skip: Int) {
    commentElementResponses(where: $where, sort: $sort, limit: $limit, skip: $skip) {
      items {
        id
        elementId
        publicationId
        response
        createdOn
        changedOn
        user {
          userName
          id
          mijnEoId
        }
        labels {
          items {
            id
            label
          }
        }
        reactions {
          meta {
            total
            totalLikes
            totalHearts
            totalDislikes
          }
        }
        childs {
          meta {
            total
          }
        }
        toxicity
      }
      meta {
        total
      }
    }
  }
`)

const GET_OPEN_SUBRESPONSES = gql(`
  query GetOpenResponsesChilds($where: CommentElementResponseWhereInput, $sort: CommentElementResponseSortInput, $limit: Int, $skip: Int) {
    commentElementResponses(where: $where, sort: $sort, limit: $limit, skip: $skip) {
      items {
        id
        elementId
        publicationId
        response
        createdOn
        changedOn
        user {
          userName
          mijnEoId
        }
        reactions {
          meta {
            totalLikes
          }
        }
        toxicity
      }
      meta {
        total
      }
    }
  }
`)

export const getOpenResponses = async (
  client: ApolloClient<object>,
  {
    pollId,
    publicationId,
    parentId = null,
    limit = 10,
    skip = 0,
  }: {
    pollId: string
    publicationId: string
    parentId?: string | null
    limit?: number | null
    skip?: number | null
  },
) => {
  let query: DocumentNode
  let variables: QueryCommentElementResponsesArgs = {
    limit,
    skip,
  }
  if (!!parentId) {
    // use subresponse query
    query = GET_OPEN_SUBRESPONSES
    variables = {
      ...variables,
      where: {
        parentId: {
          EQ: parentId,
        },
      },
      sort: {
        createdOn: 'ASC',
      },
    }
  } else {
    // use main response query
    query = GET_OPEN_RESPONSES
    variables = {
      ...variables,
      where: {
        elementId: {
          EQ: pollId,
        },
        publicationId: {
          EQ: publicationId,
        },
        parentId: {
          EQ: null,
        },
      },
      sort: {
        amountOfReactions: 'DESC',
      },
    }
  }
  const { data } = await client.query<
    { commentElementResponses: CommentElementResponses },
    QueryCommentElementResponsesArgs
  >({
    query, // if parentId given, get as subresponse
    variables,
  })

  return data.commentElementResponses
}
