import type { ApolloClient } from '@apollo/client'
import type {
  CommentElementSimpleResponses,
  CommentElementSimpleResponseWhereInput,
} from '@eo/graphql-types'
import type { CommentLabel } from '@eo/next-ui'

import { gql } from '@apollo/client'
import { getCommentLabels } from '@eo/next-ui'

export const getOpenSimpleResponseCounts = async (
  client: ApolloClient<object>,
  {
    pollId,
    publicationId,
  }: {
    pollId: string
    publicationId: string
  },
) => {
  const { commentLabels } = getCommentLabels()
  const labels = Object.keys(commentLabels)
  const indices = Array(labels.length)
    .fill(0)
    .map((_, index) => index)

  const params = indices
    .map((index) => `$where${index}: CommentElementSimpleResponseWhereInput!`)
    .join(',')
  const body = indices
    .map(
      (
        index,
      ) => `commentElementSimpleResponses${index}: commentElementSimpleResponses(where: $where${index}) {
        meta {
          total
        }
      }`,
    )
    .join('\n')

  const variables = Object.fromEntries(
    labels.map((label, index) => [
      `where${index}`,
      {
        elementId: {
          EQ: pollId,
        },
        publicationId: {
          EQ: publicationId,
        },
        label: {
          EQ: label,
        },
      } satisfies CommentElementSimpleResponseWhereInput,
    ]),
  )

  const query = gql`query GetOpenSimpleResponsesRoot(${params}){
    ${body}
  }`

  const { data } = await client.query<
    Record<`commentElementSimpleResponses${number}`, CommentElementSimpleResponses>,
    Record<string, CommentElementSimpleResponseWhereInput>
  >({
    query,
    variables,
  })

  return Object.fromEntries(
    labels.map((label, index) => [
      label as CommentLabel,
      data[`commentElementSimpleResponses${index}`]?.meta?.total,
    ]),
  ) as Record<CommentLabel, number | undefined>
}
