'use client'

import type { ReactNode } from 'react'

import { useApolloClient } from '@apollo/client'
import { useUser } from '@auth0/nextjs-auth0/client'
import { InteractionContextProvider } from '@eo/next-ui'

import { createElementReaction } from '../actions/createElementReaction'
import { createMultipleChoiceResponse } from '../actions/createMultipleChoiceResponse'
import { createNewsletterSubscription } from '../actions/createNewsletterSubscription'
import { createOpenResponse } from '../actions/createOpenResponse'
import { createOpenResponseReaction } from '../actions/createOpenResponseReaction'
import { createOpenSimpleResponse } from '../actions/createOpenSimpleResponse'
import { deleteElementReaction } from '../actions/deleteElementReaction'
import { deleteOpenResponse } from '../actions/deleteOpenResponse'
import { deleteOpenResponseReaction } from '../actions/deleteOpenResponseReaction'
import { deleteOpenSimpleResponse } from '../actions/deleteOpenSimpleResponse'
import { editOpenResponse } from '../actions/editOpenResponse'
import { editOpenSimpleResponse } from '../actions/editOpenSimpleResponse'
import { getInteractionUser } from '../actions/getInteractionUser'
import { getUserMultipleChoiceResponse } from '../actions/getUserMultipleChoiceResponse'
import { getUserOpenResponses } from '../actions/getUserOpenResponses'
import { getUserOpenSimpleResponse } from '../actions/getUserOpenSimpleResponse'
import { getElementReactions } from '../data/getElementReactions'
import { getMultipleChoiceCounts } from '../data/getMultipleChoiceCounts'
import { getOpenResponses } from '../data/getOpenResponses'
import { getOpenSimpleResponseCounts } from '../data/getOpenSimpleResponseCounts'

// get from internal api for extra caching layer
const getInteractionCounts = ({ publicationId }: { publicationId: string }) =>
  fetch(`/api/interaction-counts/${publicationId}`).then((response) => response.json())

export function InteractionWrapper({ children }: { children: ReactNode }) {
  const client = useApolloClient()
  const { user } = useUser()

  return (
    <InteractionContextProvider
      createElementReaction={createElementReaction}
      createMultipleChoiceResponse={createMultipleChoiceResponse}
      createNewsletterSubscription={createNewsletterSubscription}
      createOpenResponse={createOpenResponse}
      createOpenResponseReaction={createOpenResponseReaction}
      createOpenSimpleResponse={createOpenSimpleResponse}
      deleteElementReaction={deleteElementReaction}
      deleteOpenResponse={deleteOpenResponse}
      deleteOpenResponseReaction={deleteOpenResponseReaction}
      deleteOpenSimpleResponse={deleteOpenSimpleResponse}
      editOpenResponse={editOpenResponse}
      editOpenSimpleResponse={editOpenSimpleResponse}
      getElementReactions={(args) => getElementReactions(client, args)}
      getInteractionCounts={getInteractionCounts}
      getInteractionUser={getInteractionUser}
      getMultipleChoiceCounts={(args) => getMultipleChoiceCounts(client, args)}
      getOpenResponses={(args) => getOpenResponses(client, args)}
      getOpenSimpleResponseCounts={(args) => getOpenSimpleResponseCounts(client, args)}
      getUserMultipleChoiceResponse={getUserMultipleChoiceResponse}
      getUserOpenResponses={getUserOpenResponses}
      getUserOpenSimpleResponse={getUserOpenSimpleResponse}
      openResponseLikeTypes={['like', 'dislike']}
      user={user}
    >
      {children}
    </InteractionContextProvider>
  )
}
