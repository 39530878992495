import type { ApolloClient } from '@apollo/client'
import type { PollElementOptions, PollElementOptionsInsertInput } from '@eo/graphql-types'

import { gql } from '@apollo/client'

const GET_MULTIPLECHOICE_COUNTS = gql(`
  query GetsertPollElementOptions($input: PollElementOptionsInsertInput!) {
    getsertPollElementOptions(input: $input) {
      items {
        id
        optionId
        pollElementOptionResponses {
          meta {
            total
          }
        }
      }
    }
  }
`)

export const getMultipleChoiceCounts = async (
  client: ApolloClient<object>,
  {
    publicationId,
    pollId,
    optionIds,
  }: { publicationId: string; pollId: string; optionIds: string[] },
) => {
  const { data } = await client.query<
    { getsertPollElementOptions: PollElementOptions },
    { input: PollElementOptionsInsertInput }
  >({
    query: GET_MULTIPLECHOICE_COUNTS,
    variables: {
      input: {
        publicationId,
        elementId: pollId,
        optionIds,
      },
    },
  })

  return data.getsertPollElementOptions?.items || []
}
