// Next.js does not expose its own router history, so we created our own provider to know the last Next.js url
// based on https://github.com/vercel/next.js/discussions/36723#discussioncomment-7923119

'use client'

import type { PropsWithChildren } from 'react'

import { createContext, useEffect, useRef } from 'react'
import { usePathname } from 'next/navigation'

export const PreviousPathnameContext = createContext<string | undefined>(undefined)

export default function PreviousPathnameProvider({ children }: PropsWithChildren<object>) {
  const pathname = usePathname()
  const ref = useRef<string>()

  useEffect(() => {
    ref.current = pathname
  }, [pathname])

  return (
    <PreviousPathnameContext.Provider value={ref.current}>
      {children}
    </PreviousPathnameContext.Provider>
  )
}
