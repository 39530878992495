'use client'

import type { ApolloClient } from '@apollo/client'
import type { ElementReactions, ElementReactionWhereInput } from '@eo/graphql-types'

import { gql } from '@apollo/client'

const GET_ELEMENT_REACTIONS = gql(`
  query GetElementReactions($where: ElementReactionWhereInput) {
    elementReactions(where: $where) {
      meta {
        total
        totalLikes
        totalHearts
        totalDislikes
      }
    }
  }
`)

export async function getElementReactions(
  client: ApolloClient<object>,
  {
    elementId,
    publicationId,
  }: {
    elementId: string
    publicationId: string
  },
) {
  const { data } = await client.query<
    { elementReactions: ElementReactions },
    { where: ElementReactionWhereInput }
  >({
    query: GET_ELEMENT_REACTIONS,
    variables: { where: { elementId: { EQ: elementId }, publicationId: { EQ: publicationId } } },
  })

  return data.elementReactions?.meta || {}
}
